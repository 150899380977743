<template>
	<div class="flex flex-col">
		<div class="grid grid-cols-12">
			<div class="col-span-full">
				<div
					class="grid grid-cols-6 xl:grid-cols-12 bg-gradient-to-b from-[#0B101E] to-[#18191B] rounded-b-2xl py-12">
					<div class="col-span-full xl:col-span-2 flex items-center justify-center">
						<img src="@/assets/images/logo.svg" alt="Melodity lock explorer" class="object-contain h-36">
					</div>
					<div class="col-span-6 xl:col-start-4 flex items-center justify-center text-white xl:my-0 my-8
						relative xl:px-0 md:px-32 px-4">
						<div class="relative border rounded-full w-full xl:ml-12">
							<i class='bx bx-search absolute top-1/2 -translate-y-1/2 left-4 text-xl'></i>
							<input class="py-3 border-none pl-12 pr-4 outline-none w-full bg-transparent"
							       type="text" placeholder="Wallet address" v-model="address" id="wallet-address"
							       @keydown.enter="retrieveLocks" @input="retrieveLocks">
						</div>
						<div @click="retrieveLocks"
						     class="cursor-pointer rounded-full ml-10 px-12 py-3 select-none bg-[#5D5FEF]
						        transition-all duration-300 hover:bg-[#4245a7] absolute xl:relative right-0
								xl:translate-x-0 translate-x-[-85%] lg:block hidden font-poppins font-semibold">
							Explore
						</div>
					</div>
					<div class="col-span-full xl:col-start-10 xl:col-span-2 text-white flex items-center justify-center
						xl:pl-20 md:px-0 sm:px-16 px-4">
						<div :class="walletClass.container"
						     class="max-w-full flex items-center">
							<div class="rounded-full h-12 w-12 cursor-pointer select-none bg-[#47D680] flex
								items-center justify-center transition-all duration-300 hover:bg-[#40c173]"
							     :style="walletClass.button_shadow"
							     @click="connectWallet">
								<i class='bx bxs-wallet text-xl'></i>
							</div>
							<span class="ml-2 truncate max-w-[80%] xl:max-w-[66%] font-roboto">
								{{ connected_as }}
							</span>
						</div>
					</div>
					<div class="col-span-6 col-start-4 row-start-2 xl:flex items-center -mt-12 hidden"
					     v-if="errors.length > 0">
						<ul class="ml-12">
							<li v-for="(elem, id) of errors" :key="id" class="text-red-500">
								{{ elem }}
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="mt-12 flex flex-col items-center justify-center col-span-10 col-start-2 md:col-span-8 md:col-start-3
				relative"
			     v-if="!(address === null || address === '' || errors.length > 0)">
				<a class="p-3 -top-8 -right-8 absolute text-3xl text-white" target="_blank" rel="noopener"
				   href="https://docs.melodity.org/faq/how-to-use-meloditys-lock-explorer">
					<i class='bx bxs-info-circle'></i>
				</a>
				<h2 class="text-xl text-white text-center max-w-full font-poppins font-semibold">
					Found {{ locks.length }} lock{{ locks.length > 1 ? "s" : "" }} for
				</h2>
				<div class="text-[#5D5FEF] truncate max-w-full mb-12 text-xl text-center font-poppins font-semibold">
					{{ address }}
				</div>
				<div v-for="(elem, id) of locks" :key="id"
				     class="px-12 py-8 rounded-[2rem] my-4 grid xl:grid-cols-4 xl:gap-2 bg-gradient-to-r w-full"
				     :class="locksClass.container[id]"
				     :style="locksClass.container_shadow[id]">
					<div class="flex items-center xl:col-span-2">
						<div class="h-16 w-16 mr-6 md:flex items-center hidden">
							<img :src="lockPicture[id]" alt="Lock" class="object-contain rounded-md">
						</div>
						<div class="flex flex-col justify-center w-full md:pr-8 font-roboto font-bold">
							<h3 class="font-semibold text-lg">
								Locked:
								<br class="md:hidden block">
								{{ elem.locked }} $MELD
							</h3>
							<h4 class="font-semibold text-xs hidden xl:block">
								Redemption date: {{ elem.release_time }}
							</h4>
						</div>
					</div>
					<div
						class="relative flex xl:flex-row flex-col items-center md:items-start xl:items-center justify-center xl:ml-0 md:ml-20">
						<div class="xl:absolute left-0 xl:top-1/2 xl:-translate-y-1/2 text-white select-none">
							<p class="xl:block xl:mx-0 inline-block mx-4">.</p>
							<p class="xl:block xl:mx-0 inline-block mx-4">.</p>
							<p class="xl:block xl:mx-0 inline-block mx-4">.</p>
						</div>
						<h4 class="text-xl text-white text-opacity-25 xl:mt-0 mt-2 md:text-left font-bebas">
							{{ locksClass.middle_text[id] }}
						</h4>
						<div class="absolute right-0 top-1/2 -translate-y-1/2 text-white select-none hidden xl:block">
							<p>.</p>
							<p>.</p>
							<p>.</p>
						</div>
					</div>
					<div
						class="flex items-center justify-center md:justify-start xl:justify-center text-white xl:ml-0 md:ml-20 xl:mt-0 mt-4">
						<div v-if="connected_as === address"
						     class="rounded-full xl:w-full md:w-1/2 w-2/3 py-3 select-none text-center xl:mx-8 font-roboto"
						     :class="locksClass.button_redeem[id]"
						     @click="needs_approval ? approve() : redeem(id)">
							{{ needs_approval ? "Approve" : "Redeem & Wrap" }}
						</div>
					</div>
				</div>
			</div>
			<div v-else class="mt-12 flex flex-col items-center justify-center col-span-8 col-start-3 relative">
				<a class="p-3 top-0 -right-8 absolute text-3xl text-white" target="_blank" rel="noopener"
				   href="https://docs.melodity.org/faq/how-to-use-meloditys-lock-explorer">
					<i class='bx bxs-info-circle'></i>
				</a>
				<h2 class="text-xl text-white text-center mt-64 mb-32">
					Ooops the address is empty or invalid,<br>
					use the search bar on the top of the page to find an address
				</h2>
			</div>
			<div
				class="col-span-full bg-gradient-to-r from-[#947B9E] to-[#7989B5] py-12 grid grid-cols-3 xl:grid-cols-12 mt-20">
				<div class="col-span-full xl:col-span-8 xl:col-start-3 text-white grid grid-cols-3 xl:grid-cols-7">
					<div class="flex flex-col justify-center xl:ml-0 ml-4 md:ml-20 font-poppins font-bold">
						<h3 class="text-xl">
							Activities
						</h3>
						<small class="mt-4">
							TVL: {{ tvl }} $MELD
						</small>
					</div>
					<div v-for="(elem, id) of latest_events" :key="id"
					     class="col-start-2 xl:col-start-auto col-span-2 px-4 xl:mt-0 mt-4">
						<div class="bg-gray-900 bg-opacity-10 p-8 rounded-[2rem] font-roboto font-bold">
							<h4 class="flex items-center text-lg">
								{{ elem.name }}
							</h4>
							<div class="border-b w-1/5 h-1 my-1"></div>
							<p class="text-sm">
								{{ latestEventsText[id] }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="overlay.open" class="fixed inset-0 z-50 bg-gray-900 bg-opacity-75 text-white flex flex-col items-center
			justify-center"
		     :class="overlayClass.container">
			<small v-if="overlay.multi.active" class="font-bold text-center">
				Transaction {{overlay.multi.current}} / {{overlay.multi.max}}
			</small>
			<h2 class="text-2xl mb-2 text-center">
				Transaction confirmation pending,
				<br>
				please wait
			</h2>
			<div class="mb-4">
				Tx:
				<a :href="`https://testnet.bscscan.com/tx/${overlay.tx}`" target="_blank" rel="noopener"
				   class="underline">
					{{ overlay.tx }}
				</a>
			</div>
			<h4>
				Confirmations {{ overlay.confirmations }} / 6
			</h4>
			<small v-if="overlay.time > 0" class="mt-2">
				{{ overlay.time }} seconds elapsed since submission
			</small>
		</div>
	</div>
</template>

<script>
import {DateTime} from "luxon";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

const web3 = require("web3")
const melodity_abi = require("@/assets/melodity.json")
const governance_abi = require("@/assets/governance.json")

const testnet = false

// REAL SMART CONTRACT ADDRESS: 0x13E971De9181eeF7A4aEAEAA67552A6a4cc54f43
// TESTNET SMART CONTRACT ADDRESS: 0x5EaA8Be0ebe73C0B6AdA8946f136B86b92128c55
const melodity_address = testnet ? "0x5EaA8Be0ebe73C0B6AdA8946f136B86b92128c55" : "0x13E971De9181eeF7A4aEAEAA67552A6a4cc54f43"
const governance_address = testnet ? "0x5FF819523D4e53693F1453Dc927c515a15Ad3556" : "0xfCFE6E40B47FE7879Cf30180df157Df9e9e8AE33"

// production env = api
// dev env = api-testnet
const bsc_scan_endpoint = testnet ? "api-testnet.bscscan.com" : "api.bscscan.com"

export default {
	name: 'Home',
	provide: {
		web3: null,
		melodity: null,
		governance: null,
	},
	data: () => ({
		locks: [],
		address: null,
		needs_approval: true,
		searched: false,
		errors: [],
		actions_enabled: false,
		connected: false,
		connected_as: null,
		latest_events: [],
		used_transaction_hashes: [],
		tvl: 0,
		overlay: {
			multi: {
				active: false,
				max: 0,
				current: 0
			},
			open: false,
			confirmations: 0,
			tx: "",
			time: 0,
			interval: -1
		}
	}),
	methods: {
		prettyNumber(x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
		},
		renderNumber(value) {
			// if the provided value is not a string stringify it
			if (!(value instanceof String)) {
				value = value.toString()
			}

			let integer = "0"

			// check if the value has at least an integer part, in case it has one, prettify it
			if (value.length > 18) {
				integer = this.prettyNumber(value.substr(0, value.length - 18))
			}

			let decimals = "00"

			// check if the value has integer part
			if (value.length > 18) {
				// if it has retrieve only the decimal part
				decimals = value.substr(value.length - 18)
			} else {
				// otherwise it is a decimal number, take it all
				decimals = `${"0".repeat(18 - value.length)}${value}`
			}

			for (let i = decimals.length - 1; i >= 2; i--) {
				// remove last zeros if any
				if (decimals[i] === "0") {
					decimals = decimals.slice(0, -1)
				} else {
					break
				}
			}

			return `${integer},${decimals.substring(0, 2)}`
		},

		retrieveLocks() {
			this.locks = []
			this.errors = []

			if (this.address === null || this.address === "" || this.address.match(/^0x[a-fA-F0-9]{40}$/) === null) {
				this.errors = [
					"Please enter a valid wallet address"
				]
				return;
			}

			this.melodity.methods.locksOf(this.address).call().then((r) => {
				this.searched = true

				this.locks = r.map(v => ({
					release_time: DateTime.fromSeconds(+v.release_time).toFormat("dd-LL-yyyy HH:mm:ss"),
					locked: this.renderNumber(v.locked),
					released: v.released
				}))
			})
		},
		async connectWallet() {
			const providerOptions = {
				walletconnect: {
					package: WalletConnectProvider, // required
					options: {
						rpc: {
							56: 'https://bsc-dataseed1.binance.org',
							97: "https://data-seed-prebsc-1-s1.binance.org:8545/"
						},
						network: 'binance',
						chainId: testnet ? 97 : 56,
					}
				},
				"custom-binancechainwallet": {
					display: {
						logo: require("@/assets/images/binance.png"),
						name: "Binance Chain Wallet",
						description: "Connect to your Binance Chain Wallet"
					},
					package: true,
					connector: async () => {
						let provider = null;
						if (typeof window.BinanceChain !== 'undefined') {
							provider = window.BinanceChain;
							try {
								await provider.request({method: 'eth_requestAccounts'})
							} catch (error) {
								throw new Error("User Rejected");
							}
						} else {
							throw new Error("No Binance Chain Wallet found");
						}
						return provider;
					}
				},
			};
			const web3Modal = new Web3Modal({
				network: "binance",
				cacheProvider: true, // optional
				providerOptions // required
			})
			const provider = await web3Modal.connect();

			this.web3 = new web3(provider)

			this.melodity = new this.web3.eth.Contract(melodity_abi, melodity_address)
			this.governance = new this.web3.eth.Contract(governance_abi, governance_address)

			// try to connect to a wallet
			try {
				let r;
				if (!!provider.wc) {
					r = await this.web3.eth.getAccounts()
				} else {
					r = await this.web3.eth.requestAccounts()
				}

				// mark the user as connected and save the wallet address
				this.connected = true
				this.connected_as = r[0]

				let allowance = await this.melodity.methods.allowance(this.connected_as, governance_address).call()
				if (BigInt(allowance) > BigInt(`1${"0".repeat(29)}`)) {
					this.needs_approval = false
				}

				// send a notification stating a successfull connection
				this.$toastify({
					text: `
						<div class="flex items-center">
							<i class='bx bx-check-double text-2xl mr-4'></i>
							<div class="flex flex-col">
								<small class="font-semibold">Wallet connected!</small>
								<p>
									Successfully connected as ${r[0]}
								</p>
							</div>
						</div>
					`,
					duration: 5000,
					gravity: "top", // `top` or `bottom`
					position: "right", // `left`, `center` or `right`
					stopOnFocus: true, // Prevents dismissing of toast on hover
					style: {
						background: "#059669",
					},
					escapeMarkup: false
				}).showToast()
			} catch (r) {
				// an error occurred, show an error message and go on
				this.$toastify({
					text: `
						<div class="flex items-center">
							<i class='bx bx-error text-2xl mr-4'></i>
							<div class="flex flex-col">
								<small class="font-semibold">Error - code: ${r.code}</small>
								<p>
									${r.message}
								</p>
							</div>
						</div>
					`,
					duration: 5000,
					gravity: "top", // `top` or `bottom`
					position: "right", // `left`, `center` or `right`
					stopOnFocus: true, // Prevents dismissing of toast on hover
					style: {
						background: "#DC2626",
					},
					escapeMarkup: false
				}).showToast()
			}
		},
		async approve() {
			if (this.needs_approval) {
				// open a popup overlay
				this.overlay.confirmations = 0
				this.overlay.open = true

				const from = this.connected_as
				await this.melodity.methods.approve(governance_address, `1${"0".repeat(70)}`).send({from})
					.on("transactionHash", (tx) => {
						this.overlay.tx = tx
						setTimeout(() => {
							if (this.overlay.open && this.overlay.confirmations === 0) {
								this.overlay.confirmations++
							}
						}, 5000)
						setTimeout(() => {
							if (this.overlay.open && this.overlay.confirmations === 1) {
								this.overlay.confirmations++
							}
						}, 10000)

						this.overlay.interval = setInterval(() => {
							if (this.overlay.open) {
								this.overlay.time++
							} else {
								clearInterval(this.overlay.interval)
							}
						}, 1000)
					})
					.on("confirmation", (confirmations) => {
						if (this.overlay.open) {
							this.overlay.confirmations = confirmations
						}
					})
					.on("receipt", r => {
						// transaction confirmed
						this.overlay.open = false

						this.needs_approval = false

						// send a notification stating a successful transaction
						this.$toastify({
							text: `
								<div class="flex items-center">
									<i class='bx bx-check-double text-2xl mr-4'></i>
									<div class="flex flex-col">
										<small class="font-semibold">Wrap approved!</small>
									</div>
								</div>
							`,
							duration: 5000,
							gravity: "top", // `top` or `bottom`
							position: "right", // `left`, `center` or `right`
							stopOnFocus: true, // Prevents dismissing of toast on hover
							style: {
								background: "#059669",
							},
							escapeMarkup: false
						}).showToast()
					})
					.on("error", r => {
						this.overlay.open = false

						// an error occurred, show an error message and go on
						this.$toastify({
							text: `
						<div class="flex items-center">
							<i class='bx bx-error text-2xl mr-4'></i>
							<div class="flex flex-col">
								<small class="font-semibold">Error - code: ${r.code}</small>
								<p>
									${r.message}
								</p>
							</div>
						</div>
					`,
							duration: 5000,
							gravity: "top", // `top` or `bottom`
							position: "right", // `left`, `center` or `right`
							stopOnFocus: true, // Prevents dismissing of toast on hover
							style: {
								background: "#DC2626",
							},
							escapeMarkup: false
						}).showToast()
					})
			}
		},
		async redeem(id) {
			// check the lock can be redeemed
			if (DateTime.fromFormat(this.locks[id].release_time, "dd-LL-yyyy HH:mm:ss").toMillis() < Date.now() &&
				!this.locks[id].released) {
				// open a popup overlay
				this.overlay.confirmations = 0
				this.overlay.open = true
				this.overlay.multi.active = true
				this.overlay.multi.max = 2
				this.overlay.multi.current = 1

				const from = this.connected_as

				// release funds
				await this.melodity.methods.release(id).send({from})
					.on("transactionHash", (tx) => {
						this.overlay.tx = tx
						setTimeout(() => {
							if (this.overlay.open && this.overlay.confirmations === 0) {
								this.overlay.confirmations++
							}
						}, 5000)
						setTimeout(() => {
							if (this.overlay.open && this.overlay.confirmations === 1) {
								this.overlay.confirmations++
							}
						}, 10000)

						this.overlay.interval = setInterval(() => {
							if (this.overlay.open) {
								this.overlay.time++
							} else {
								clearInterval(this.overlay.interval)
							}
						}, 1000)
					})
					.on("confirmation", (confirmations) => {
						if (this.overlay.open) {
							this.overlay.confirmations = confirmations
						}
					})
					.on("receipt", r => {
						this.locks[id].released = true

						// send a notification stating a successful transaction
						this.$toastify({
							text: `
								<div class="flex items-center">
									<i class='bx bx-check-double text-2xl mr-4'></i>
									<div class="flex flex-col">
										<small class="font-semibold">Lock released!</small>
										<p>
											Successfully released ${this.locks[id].locked} $MELD
										</p>
									</div>
								</div>
							`,
							duration: 5000,
							gravity: "top", // `top` or `bottom`
							position: "right", // `left`, `center` or `right`
							stopOnFocus: true, // Prevents dismissing of toast on hover
							style: {
								background: "#059669",
							},
							escapeMarkup: false
						}).showToast()
					})
					.on("error", r => {
						this.overlay.open = false

						// an error occurred, show an error message and go on
						this.$toastify({
							text: `
						<div class="flex items-center">
							<i class='bx bx-error text-2xl mr-4'></i>
							<div class="flex flex-col">
								<small class="font-semibold">Error - code: ${r.code}</small>
								<p>
									${r.message}
								</p>
							</div>
						</div>
					`,
							duration: 5000,
							gravity: "top", // `top` or `bottom`
							position: "right", // `left`, `center` or `right`
							stopOnFocus: true, // Prevents dismissing of toast on hover
							style: {
								background: "#DC2626",
							},
							escapeMarkup: false
						}).showToast()
					})

				let [integer, decimal] = this.locks[id].locked.replaceAll(".", "").split(",")
				let value = `${integer}${decimal.padEnd(18, "0")}`

				// wrap meld
				await this.governance.methods.wrap(value).send({from})
					.on("transactionHash", (tx) => {
						// open a popup overlay
						this.overlay.multi.current++
						this.overlay.tx = tx
						this.overlay.confirmations = 0
            this.overlay.time = 0

						setTimeout(() => {
							if (this.overlay.open && this.overlay.confirmations === 0) {
								this.overlay.confirmations++
							}
						}, 5000)
						setTimeout(() => {
							if (this.overlay.open && this.overlay.confirmations === 1) {
								this.overlay.confirmations++
							}
						}, 10000)
					})
					.on("confirmation", (confirmations) => {
						if (this.overlay.open) {
							this.overlay.confirmations = confirmations
						}
					})
					.on("receipt", r => {
						// transaction confirmed
						this.overlay.open = false

						// send a notification stating a successful transaction
						this.$toastify({
							text: `
								<div class="flex items-center">
									<i class='bx bx-check-double text-2xl mr-4'></i>
									<div class="flex flex-col">
										<small class="font-semibold">Wrap completed!</small>
										<p>
											Successfully wrapped ${this.locks[id].locked} $MELD
										</p>
									</div>
								</div>
							`,
							duration: 5000,
							gravity: "top", // `top` or `bottom`
							position: "right", // `left`, `center` or `right`
							stopOnFocus: true, // Prevents dismissing of toast on hover
							style: {
								background: "#059669",
							},
							escapeMarkup: false
						}).showToast()
					})
					.on("error", r => {
						this.overlay.open = false

						// an error occurred, show an error message and go on
						this.$toastify({
							text: `
						<div class="flex items-center">
							<i class='bx bx-error text-2xl mr-4'></i>
							<div class="flex flex-col">
								<small class="font-semibold">Error - code: ${r.code}</small>
								<p>
									${r.message}
								</p>
							</div>
						</div>
					`,
							duration: 5000,
							gravity: "top", // `top` or `bottom`
							position: "right", // `left`, `center` or `right`
							stopOnFocus: true, // Prevents dismissing of toast on hover
							style: {
								background: "#DC2626",
							},
							escapeMarkup: false
						}).showToast()
					})
			}
		},

		async retrieveReleaseLockNotifications() {
			let response = await fetch(`https://${bsc_scan_endpoint}/api?
				module=logs&action=getLogs&fromBlock=0&toBlock=latest&address=${melodity_address}&
				topic0=0xb21fb52d5749b80f3182f8c6992236b5e5576681880914484d7f4c9b062e619e&apikey=3WQHM36RGMQ58KQKCMWV3F2Z4X9C7VUETE`)

			return (await response.json()).result.slice(-3)
		},
		async retrieveLockNotifications() {
			let response = await fetch(`https://${bsc_scan_endpoint}/api?
				module=logs&action=getLogs&fromBlock=0&toBlock=latest&address=${melodity_address}&
				topic0=0x9f1ec8c880f76798e7b793325d625e9b60e4082a553c98f42b6cda368dd60008&apikey=3WQHM36RGMQ58KQKCMWV3F2Z4X9C7VUETE`)

			return (await response.json()).result.slice(-3)
		},
		async retrieveTransferNotifications() {
			let response = await fetch(`https://${bsc_scan_endpoint}/api?
				module=logs&action=getLogs&fromBlock=0&toBlock=latest&address=${melodity_address}&
				topic0=0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef&apikey=3WQHM36RGMQ58KQKCMWV3F2Z4X9C7VUETE`)

			return (await response.json()).result.slice(-3)
		},
		retrieveNotifications() {
			// release lock
			Promise.all([
				this.retrieveReleaseLockNotifications(),
				this.retrieveLockNotifications(),
				this.retrieveTransferNotifications()
			]).then(({0: a, 1: b, 2: c}) => {
				let latest_events = []

				for (let i = 0; i < a.length; i++) {
					let timestamp = a[i].timeStamp
					let tmp = this.web3.eth.abi.decodeLog([
							{
								type: 'address',
								name: 'account'
							},
							{
								type: 'uint256',
								name: 'amount'
							}
						],
						a[i].data,
						a[i].topics
					)

					latest_events.push({
						name: "Release",
						data: {
							account: tmp.account,
							amount: tmp.amount
						},
						timestamp: parseInt(timestamp, 16)
					})
				}

				for (let i = 0; i < b.length; i++) {
					let timestamp = b[i].timeStamp
					let tmp = this.web3.eth.abi.decodeLog([
							{
								type: 'address',
								name: 'account'
							},
							{
								type: 'uint256',
								name: 'amount'
							}
						],
						b[i].data,
						b[i].topics
					)

					latest_events.push({
						name: "Lock",
						data: {
							account: tmp.account,
							amount: tmp.amount
						},
						timestamp: parseInt(timestamp, 16)
					})
				}

				for (let i = 0; i < c.length; i++) {
					let timestamp = c[i].timeStamp
					let tmp = this.web3.eth.abi.decodeLog([
							{
								type: 'address',
								name: 'from',
								indexed: true
							},
							{
								type: 'address',
								name: 'to',
								indexed: true
							},
							{
								type: 'uint256',
								name: 'value'
							}
						],
						c[i].data,
						c[i].topics.slice(-2)
					)

					latest_events.push({
						name: "Transfer",
						data: {
							from: tmp.from,
							to: tmp.to,
							amount: tmp.value
						},
						timestamp: parseInt(timestamp, 16)
					})
				}

				// latest events on top of the array
				this.latest_events = latest_events.sort((a, b) => a.timestamp > b.timestamp ? -1 : 1).slice(0, 3)

				this.melodity.events.Released({
					fromBlock: "latest"
				}, this.updateLatestEvents)

				this.melodity.events.Locked({
					fromBlock: "latest"
				}, this.updateLatestEvents)

				this.melodity.events.Transfer({
					fromBlock: "latest"
				}, this.updateLatestEvents)
			})
		},
		updateLatestEvents(err, ev) {
			if (!err && ev && !this.used_transaction_hashes.includes(ev.id)) {
				this.used_transaction_hashes.push(ev.id)
				switch (ev.event) {
					case "Released":
						this.latest_events.pop()
						this.latest_events.unshift({
							name: "Release",
							data: {
								account: ev.returnValues.account,
								amount: ev.returnValues.amount
							},
							timestamp: DateTime.now().toSeconds()
						})
						break;
					case "Locked":
						this.latest_events.pop()
						this.latest_events.unshift({
							name: "Lock",
							data: {
								account: ev.returnValues.account,
								amount: ev.returnValues.amount
							},
							timestamp: DateTime.now().toSeconds()
						})
						break;
					case "Transfer":
						this.latest_events.pop()
						this.latest_events.unshift({
							name: "Transfer",
							data: {
								from: ev.returnValues.from,
								to: ev.returnValues.to,
								amount: ev.returnValues.value
							},
							timestamp: DateTime.now().toSeconds()
						})
						break;
				}
			}
		}
	},
	computed: {
		walletClass() {
			return {
				container: this.connected_as !== null ? `select-none bg-[#47D680] rounded-full` : ``,
				button_shadow: this.connected_as !== null ? `box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1),
					0 1px 2px 0 rgba(0, 0, 0, 0.06)` : ``
			}
		},
		locksClass() {
			return {
				container: this.locks.map(v =>
					v.released || DateTime.fromFormat(v.release_time, "dd-LL-yyyy HH:mm:ss").toMillis() > Date.now() ?
						`from-[#012A43] to-[#2E0843] text-[#707070]` :     // locked or redeemed
						`from-[#414A65] to-[#5A4663] text-white`           // redeemable
				),
				container_shadow: this.locks.map(v =>
					v.released || DateTime.fromFormat(v.release_time, "dd-LL-yyyy HH:mm:ss").toMillis() > Date.now() ?
						`box-shadow: 0 4px 4px 0 rgba(69, 16, 98, .22)` :           // locked or redeemed
						`box-shadow: 0 4px 4px 0 rgba(215, 169, 235, .12)`          // redeemable
				),
				button_redeem: this.locks.map(v =>
					v.released || DateTime.fromFormat(v.release_time, "dd-LL-yyyy HH:mm:ss").toMillis() > Date.now() ?
						`bg-[#414A65]` :                    // deactivated
						`bg-[#47D680] cursor-pointer transition-all duration-300 hover:bg-[#40c173]`       // activated
				),
				middle_text: this.locks.map(v =>
					v.released ? `Redeemed` : (
						DateTime.fromFormat(v.release_time, "dd-LL-yyyy HH:mm:ss").toMillis() > Date.now() ?
							v.release_time : `Available now`
					)
				),
			}
		},
		latestEventsText() {
			return this.latest_events.map(v => {
				return v.name === "Transfer" ?
					`Transferred ${this.renderNumber(v.data.amount)} $MELD` : (
						v.name === "Lock" ?
							`Locked ${this.renderNumber(v.data.amount)} $MELD` :
							`Released ${this.renderNumber(v.data.amount)} $MELD`
					)
			})
		},
		overlayClass() {
			return {
				container: this.overlay.open ? `opacity-1` : `opacity-0`
			}
		},
		lockPicture() {
			return this.locks.map(v =>
				v.released ? require("@/assets/images/redeemed.webp") :
					(
						DateTime.fromFormat(v.release_time, "dd-LL-yyyy HH:mm:ss").toMillis() > Date.now() ?
							require("@/assets/images/lock-on.webp") : require("@/assets/images/lock-off.webp")
					)
			)
		}
	},
	created() {
		this.web3 = new web3(testnet ? "https://data-seed-prebsc-1-s1.binance.org:8545/" : "https://bsc-dataseed1.binance.org:443")
		this.melodity = new this.web3.eth.Contract(melodity_abi, melodity_address)

		this.melodity.methods.total_locked_amount().call().then((r) => {
			this.tvl = this.renderNumber(r)
		})
		this.retrieveNotifications()

		setInterval(() => {
			this.used_transaction_hashes.shift()
		}, 500)
	}
}
</script>
