<template>
	<main>
		<Home></Home>
	</main>
</template>

<script>
import Home from "@/views/Home";

export default {
	components: {Home}
}
</script>

<style lang="scss">
</style>