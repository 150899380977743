import { createApp } from 'vue'
import App from './App.vue'

import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

import 'boxicons/css/boxicons.min.css'

import "./assets/tailwind.css"
import "./assets/main.scss"

const app = createApp(App)
app.config.globalProperties.$toastify = Toastify
app.mount('#app')